











































































import { defineComponent } from "@vue/composition-api";
import useFormModel from "@/apps/core/modules/useFormModel";
import { PenarikanFM } from "@/apps/tabungan/models/penarikanForm";
import { SaveContext } from "@/apps/core/models/formModel";
import { listVM } from "@/apps/tabungan/modules/stores/penarikan";
import {
  DialogProgrammatic as Dialog
} from "buefy";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "PenarikanForm",
  components: {
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const form = new PenarikanFM(["nasabah", "nilai"], ["catatan"]);
    const composition = useFormModel(form);

    const savePenarikan = async () => {
      await form.save(SaveContext.Create);
        listVM.setRefreshRequest("penarikan");
    };

    const confirm = () => {
      Dialog.confirm({
        message: "Anda yakin akan melakukan penarikan?",
        confirmText: "Ya",
        cancelText: "Tidak",
        onConfirm: () => savePenarikan(),
      });
    };
    return {
      // Composition
      ...composition,

      // Method
      confirm
    };
  },
});
