
































































import { defineComponent, reactive } from "@vue/composition-api";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import { isNasabah, isPengelola } from "@/apps/accounts/modules/store";
import { listVM } from "@/apps/tabungan/modules/stores/penarikan";

export default defineComponent({
  name: "PenarikanList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    MonthSelect: () =>
      import("@/apps/core/components/MonthSelect.vue"),
    PenarikanListBox: () => import("@/apps/tabungan/components/PenarikanListBox.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const composition = useInfiniteScrollList(listVM);
    const classContent = isNasabah.value? "column no-padding is-full":"column is-full"
    
    const action = reactive({
      type: null,
      routeName: null,
      callBack: null,
    } as Record<string, any>);

    if (isPengelola.value) {
      Object.assign(action, {
        type: "add",
        routeName: "penarikan-add",
        callBack: null,
      });
    }
    return {
      // Data
      action,
      classContent,

      // Computed
      isNasabah,

      // Composition
      ...composition,
    };
  },
});
