import FormModel from "@/apps/core/models/formModel";
import { Penarikan, PenarikanAPI } from "./penarikan";

class PenarikanFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new PenarikanAPI(),
      new Penarikan(),
      requiredFields,
      nonRequiredFields
    );
  }
}


export { PenarikanFM };
